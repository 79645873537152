import { PDFDocument, PDFPage, rgb } from "pdf-lib"

import ChildEvaluationActions from "@actions/CRUDActions/ClassActions/childEvaluationActions"
import { Child, ChildAuthorisation, ChildEvaluation, Evaluation } from "@utils/interfaces/interfaces"
import { ExtracurricularActivitiyType } from "@components/forms/SchoolForm"
import { capitalizeFirstLetter, removeKeys } from "@utils/utils/util"

import log from "loglevel"

import { ChildEvaluationKeys, EvaluationKeys } from "./types"
import { ChildrenPDFDocs, EvaluationPointCoordinates, TextConfig } from "./interfaces"
import { getEvaluationPointsCoordinates, getStandardCertificateFormCoordinates } from "./form_coordinates"
import { CertificateNames, CertificateType } from "./enum"


const getCertificateCoordinates = (key: CertificateNames, certificate_page: PDFPage, add_height: number=0, add_width: number=0, is_print_certificates_checked: boolean=false, shift_up: string='', shift_down: string='', shift_left: string='', shift_right: string='') => {
    let form_coordinates, evaluation_points_coordinates

    if (key === CertificateNames.STANDARD_CERTIFICATE)
        form_coordinates = getStandardCertificateFormCoordinates(certificate_page, add_height, add_width)
    else if (key === CertificateNames.PERCEPTUAL_MA_CERTIFICATE) {
        form_coordinates = getStandardCertificateFormCoordinates(certificate_page, add_height, add_width, is_print_certificates_checked, shift_up, shift_down, shift_left, shift_right)
        evaluation_points_coordinates = getEvaluationPointsCoordinates(certificate_page, is_print_certificates_checked, shift_up, shift_down, shift_left, shift_right)
    }
    else
        throw new Error(`'checked' key, ${key}, doesn't match 'CertificateType', ${CertificateType}`)

    return { form_coordinates, evaluation_points_coordinates }
}


const drawOnCertificate = async (
        child: Child, 
        key: CertificateNames, 
        certificate_page: PDFPage, 
        todays_date: string,
        unwanted_keys_in_child_evaluation: ChildEvaluationKeys[],
        term_number: number,
        text: TextConfig, 
        evaluation: Evaluation | null,
        instructor_name: string | null,
        add_height: number=0, 
        add_width: number=0,
        is_print_certificates_checked: boolean=false,
        shift_up: string='',
        shift_down: string='',
        shift_left: string='',
        shift_right: string=''
    ) => {

    const {form_coordinates, evaluation_points_coordinates: evaluation_points} = getCertificateCoordinates(key as CertificateNames, certificate_page, add_height, add_width, is_print_certificates_checked, shift_up, shift_down, shift_left, shift_right)
    const evaluation_points_coordinates = evaluation_points as { [index in EvaluationKeys]: EvaluationPointCoordinates }

    // fill out child details, class and instructor
    certificate_page.drawText(capitalizeFirstLetter(child.first_name), {...form_coordinates.name_coord, ...text})
    if (child.last_name)
        certificate_page.drawText(capitalizeFirstLetter(child.last_name), {...form_coordinates.surname_coord, ...text})
    certificate_page.drawText(todays_date, {...form_coordinates.date_coord, ...text})
    certificate_page.drawText(`${term_number}`, {...form_coordinates.term_coord, ...text})
    if (evaluation)
        certificate_page.drawText(capitalizeFirstLetter(evaluation.instructor_name), {...form_coordinates.instructor_name_coord, ...text})
    else if (instructor_name)
        certificate_page.drawText(capitalizeFirstLetter(instructor_name), {...form_coordinates.instructor_name_coord, ...text})
    else
        throw new Error("Evaluation or instructor name must be passed in")

    // fill out child evaluation points
    if (evaluation && key === CertificateNames.PERCEPTUAL_MA_CERTIFICATE) {

        const child_evaluation_actions = new ChildEvaluationActions()
        const child_evaluations: ChildEvaluation[] = await child_evaluation_actions.get(undefined, undefined, {child: child.id, evaluation: evaluation.id})
        const child_evaluation = child_evaluations[0]  // there will only ever be one child evaluation if filter by child and evaluation
        const parsed_child_evaluation = removeKeys(child_evaluation, unwanted_keys_in_child_evaluation as (keyof ChildEvaluation)[])

        for (const [key, value] of Object.entries(parsed_child_evaluation)) {
            const coords = evaluation_points_coordinates[key as EvaluationKeys]
            if (coords) {
                const color = value === 1 ? coords.red :
                                value === 2 ? coords.yellow :
                                value === 3 ? coords.green : null
                if (color) {
                    certificate_page.drawSvgPath(
                        'M8,2a6,6,0,1,0,6,6A6,6,0,0,0,8,2Zm0,9.42857A3.42857,3.42857,0,1,1,11.42857,8,3.42857,3.42857,0,0,1,8,11.42857Z', 
                        {
                            color: rgb(0, 0, 0), // rgb(253 / 255, 216 / 255, 53 / 255),
                			scale: 1.2,
                            ...color,
                        }
                    )
                }
                else 
                    log.warn(`Evaluatiion rating for '${key}' is invalid for child ${child.username}. Skipped drawing certificate cirle.`)
            }
            else 
                log.warn(`ChildEvaluation key of ${key} does not exist in 'evaluation_points_coordinates'. Skipped drawing certificate cirle.`)
        }
    }
}


const inInvalidTermChildren = async (children: Child[], country_term_id: number | 'All') => {
    let  valid_term_children: Child[] = []
    const not_in_selected_term_children: Child[] = []

    if (country_term_id === 'All') {
        valid_term_children = children
        return {valid_term_children, not_in_selected_term_children}
    }

    for (const child of children) {
        if (child.country_term_id !== country_term_id) {
            log.warn(`Child ${child.username} and child id ${child.id}, has a school not in the selected term. Skipping certificate creation for this child...`)
            not_in_selected_term_children.push(child)
            continue
        }

        valid_term_children.push(child)
    }

    return {valid_term_children, not_in_selected_term_children}
}


const cleanChildren = async (children: Child[]) => {
    const intial_cleaned_children: Child[] = []
    const not_authorised_children: Child[] = []
    const not_allocated_class: Child[] = []
    const not_ready_to_send_certificate_children: Child[] = []

    for (const child of children) {
        // if (child.authorized.toString() !== ChildAuthorisation.AUTHORISED) {
        //     log.warn(`Child ${child.username}, is not authorised. Skipping certificate creation for this child...`)
        //     not_authorised_children.push(child)
        //     continue
        // }
        
        if (!child.instructor_name) {
            log.warn(`Child ${child.username} and child id ${child.id}, has not been allocated to a class. Skipping certificate creation for this child...`)
            not_allocated_class.push(child)
            continue
        }

        if (!child.franchise_ready_to_send_certificate) {
            log.warn(`Child ${child.username} and child id ${child.id}, has their franchise not ready to send certificates. Skipping certificate creation for this child...`)
            not_ready_to_send_certificate_children.push(child)
            continue
        }

        intial_cleaned_children.push(child)
    }

    return {intial_cleaned_children, not_authorised_children, not_allocated_class, not_ready_to_send_certificate_children}
}


const cleanEmailMethodChildren = async (children: Child[]) => {
    const valid_children: Child[] = []
    const send_to_school_children: Child[] = []
    const no_parent_email_or_phone_number: Child[] = []

    for (const child of children) {
        if (!child.parent_contact_email && !child.parent_phone) {
            if (child.school_receives_certificates && child.school_activity_type === ExtracurricularActivitiyType.INTRAMURAL) {
                log.warn(`Child ${child.username} and child id ${child.id}, is an intramural child and has no parent email. Skipping certificate creation for this child...`)
                send_to_school_children.push(child)
            }
            else {
                log.warn(`Child ${child.username} and child id ${child.id}, has no parent email. Skipping certificate creation for this child...`)
                no_parent_email_or_phone_number.push(child)
                continue
            }
        }

        valid_children.push(child)
    }

    return {valid_children, send_to_school_children, no_parent_email_or_phone_number}
}


const cleanParticipationChildren = async (children: Child[]) => {
    const valid_children: Child[] = []
    const participation_certificate_already_sent_children: Child[] = []

    for (const child of children) {
        // note: we still add child to valid_children even if their certificate has already been sent
        if (!child.is_needing_participation_certificate_sent) {
            log.warn(`Child ${child.username} and child id ${child.id}, has there participation certificate already sent. Skipping certificate creation for this child...`)
            participation_certificate_already_sent_children.push(child)
            continue
        }

        valid_children.push(child)
    }

    return {valid_children, participation_certificate_already_sent_children}
}


const cleanEvaluationChildren = async (children: Child[]) => {
    const valid_children: Child[] = []
    const evaluation_certificate_already_sent_children: Child[] = []
    const evalaution_incomplete_assessment_children: Child[] = []

    for (const child of children) {
        if (!child.is_needing_evaluation_certificate_sent) {
            log.warn(`Child ${child.username} and child id ${child.id}, has there evaluation certificate already sent. Skipping certificate creation for this child...`)
            evaluation_certificate_already_sent_children.push(child)
        }

        if (child.incomplete_evaluation) {
            log.warn(`Child ${child.username} and child id ${child.id}, has an incomplete evaluation. Skipping certificate creation for this child...`)
            evalaution_incomplete_assessment_children.push(child)
            continue
        }

        valid_children.push(child)
    }

    return {valid_children, evalaution_incomplete_assessment_children, evaluation_certificate_already_sent_children}
}


const pdfDocumentToBlob = async (pdfDocument: PDFDocument): Promise<Blob> => {
    return new Blob([new Uint8Array(await pdfDocument.save())], { type: 'application/pdf' })
}


const updateChildPDFDocs = async (children_pdf_docs: ChildrenPDFDocs[], child: Child, pdf_doc: any, certificate_name: CertificateNames): Promise<ChildrenPDFDocs[]> => {
    const index = children_pdf_docs.findIndex(child_pdf_docs => child_pdf_docs.child.id === child.id)

    // commetn
    if (index !== -1) {
        children_pdf_docs[index] = {
            ...children_pdf_docs[index],
            [certificate_name]: pdf_doc
        }
    } else {
        children_pdf_docs.push({
            child: child,
            perceptual_ma_certificate: certificate_name === CertificateNames.PERCEPTUAL_MA_CERTIFICATE ? pdf_doc : null,
            standard_certificate: certificate_name === CertificateNames.STANDARD_CERTIFICATE ? pdf_doc : null,
            send_to_school: !child.parent_contact_email && !child.parent_phone && child.school_receives_certificates
        })
    }

    return children_pdf_docs
}

export {
    getCertificateCoordinates, drawOnCertificate, cleanChildren, cleanEmailMethodChildren, inInvalidTermChildren,
    cleanParticipationChildren, cleanEvaluationChildren, updateChildPDFDocs, pdfDocumentToBlob
}